<template>
  <div class="base-info">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="updateFormRules"
      label-width="120px"
      class="ruleForm"
      :size="formSize"
    >
      <div class="title">基本信息</div>
      <el-form-item label="产品类型：" prop="kindId">
        <el-radio-group v-model="ruleForm.kindId" size="large">
          <el-radio-button label="3">有限计次卡</el-radio-button>
          <!-- <el-radio-button label="4">不限计次卡</el-radio-button> -->
          <el-radio-button label="5">储值卡</el-radio-button>
          <el-radio-button label="6">折扣卡</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="卡面名称：" prop="cardName">
        <el-input v-model="ruleForm.cardName"></el-input>
      </el-form-item>
      <el-form-item label="卡面说明：" prop="cardAlias">
        <el-input v-model="ruleForm.cardAlias"></el-input>
      </el-form-item>

      <el-form-item label="本金金额：" prop="salePrice">
        <el-input @change="handleSalePriceChange" v-model="ruleForm.salePrice" maxlength="6">
          <template #suffix>| 元</template>
        </el-input>
      </el-form-item>

      <el-form-item v-if="ruleForm.kindId == 6" label="折扣：" prop="salePrice">
        <el-input v-model="ruleForm.discount">
          <template #suffix>| 折</template>
        </el-input>
        <div class="tips">例：9折，输入框内输入90</div>
      </el-form-item>

      <!-- <el-form-item v-if="ruleForm.kindId == 5" label="储值金额：" prop="cardName">
        <el-input v-model="ruleForm.exactPrice"></el-input>
      </el-form-item> -->

      <el-form-item v-if="ruleForm.kindId == 5" label="赠送金额：" prop="cardName">
        <el-input v-model="ruleForm.promPrice"></el-input>
      </el-form-item>

      <serve-form
        v-if="ruleForm.kindId < 5"
        label="购卡项目:"
        category="serve"
        :handselList="curretnOptServeList"
      >
      </serve-form>
      <el-form-item v-if="ruleForm.kindId < 5">
        <div class="other sum-price">
          <el-button @click="handleSiteHandselServeClick" size="small" plain>
            <el-icon>
              <setting /> </el-icon
            >选择服务
          </el-button>
          <div class="other-tips">
            累计价格:<span>¥{{ Number(currentOptServePrice).toFixed(2) }}</span>
          </div>
        </div>
      </el-form-item>
      <serve-form
        :isHandsel="true"
        v-if="ruleForm.kindId < 5"
        label="赠送项目:"
        category="handsel"
        :handselList="currentHandselOptList"
      >
      </serve-form>
      <el-form-item v-if="ruleForm.kindId < 5">
        <div class="other">
          <el-button @click="handleSiteHandselGoodsClick" size="small" plain>
            <el-icon>
              <setting /> </el-icon
            >配置商品权益
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="时效：" prop="timeLimitFlag">
        <div class="aging">
          <div>
            <el-radio v-model="ruleForm.timeLimitFlag" label="1" size="large">
              <span>永久有效:</span>
            </el-radio>
          </div>
          <div>
            <el-radio v-model="ruleForm.timeLimitFlag" label="2" size="large">
              <span> 时间段:</span>
            </el-radio>
            <el-date-picker
              :disabled="ruleForm.timeLimitFlag != 2"
              @change="handleDatetimerangeChange"
              v-model="ruleForm.date"
              type="datetimerange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            />
          </div>
          <div>
            <el-radio v-model="ruleForm.timeLimitFlag" label="3" size="large">
              <span>购买后:</span>
            </el-radio>
            <el-input
              :disabled="ruleForm.timeLimitFlag != 3"
              class="timeLimitDays"
              placeholder="自定义"
              v-model="ruleForm.timeLimitDays3"
            ></el-input
            >天内有效
          </div>
          <div>
            <el-radio v-model="ruleForm.timeLimitFlag" label="4" size="large">
              <span>首次使用:</span>
            </el-radio>
            <el-input
              :disabled="ruleForm.timeLimitFlag != 4"
              class="timeLimitDays"
              placeholder="自定义"
              v-model="ruleForm.timeLimitDays4"
            ></el-input
            >天内有效
          </div>
        </div>
      </el-form-item>
      <el-form-item label-width="160px" label="小程序/网店展示:" prop="displayApplet">
        <div class="expire-operation">
          <div>
            <el-radio v-model="ruleForm.displayApplet" :label="true" size="large">展示</el-radio>
            <el-radio v-model="ruleForm.displayApplet" :label="false" size="large">不展示</el-radio>
          </div>
        </div>
      </el-form-item>
      <div class="title">购买限制及使用规则</div>
      <el-form-item label-width="120px" label="过期后是否可使用：" prop="expireUseFlag">
        <div class="expire-operation">
          <div>
            <el-radio v-model="ruleForm.expireUseFlag" label="0" size="large"
              >禁止使用</el-radio
            >
            <el-radio v-model="ruleForm.expireUseFlag" label="1" size="large"
              >仍可使用</el-radio
            >
          </div>
          <div class="tips">
            *仍可使用：过期后会员仍可使用会员卡进行消费，享受相应折扣
            <br />*禁止使用：过期后会员禁止使用会员卡进行消费及相应折扣,需延期操作后方可继续消费
          </div>
        </div>
      </el-form-item>

      <!--TODO:-->
      <el-form-item label="购买限制：" prop="name">
        <div class="buy-limit">
          <el-select
            v-model="ruleForm.buyerRoleFlag"
            class="el-select"
            placeholder="购买资格"
            size="large"
          >
            <el-option
              v-for="item in buyLimit"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            :disabled="ruleForm.buyerRoleFlag == 0"
            class="buy-limit-input"
            placeholder="如填写0，则为不限制购买数量"
            v-model="ruleForm.buyRoleQty"
          >
            <template #suffix>| 张</template>
          </el-input>
        </div>
      </el-form-item>
      <div class="title">卡面设计</div>
      <el-form-item label="卡面配置：" prop="name">
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabsClick">
          <el-tab-pane name="a" label="卡片背景色">
            <el-color-picker
              @change="handleColorPickerChange"
              v-model="ruleForm.cardBgcolor"
              show-alpha
            />
          </el-tab-pane>
          <el-tab-pane name="b" label="自定义图片">
            <upload-img
              @handleUploadRemove="handleUploadRemove"
              class="card-setting"
              @handleUploadEmpty="handleUploadImgEmpty"
              listType="text"
              :limit="1"
              ref="imgUploadRef"
              @handleUploadSuccess="handleImgUploadSuccess"
            >
              点击上传图片
            </upload-img>
            <div class="tips">建议尺寸：720*400像素，最多上传1张</div>
          </el-tab-pane>
        </el-tabs>
        <div class="card-preview">
          <div class="preview-title">卡面预览</div>
          <div
            v-if="activeName == 'a'"
            :style="{ backgroundColor: ruleForm.cardBgcolor }"
            class="card-content"
          >
            <div class="top">
              <el-icon>
                <eleme-filled />
              </el-icon>
              <span class="name">一指阁健康管理</span>
            </div>
            <div class="center">
              <div class="name-info">
                <div class="card-name">
                  {{
                    ruleForm.cardName.length > 0 ? ruleForm.cardName : "这里是卡面名称"
                  }}
                </div>
                <div class="price">
                  ¥{{
                    ruleForm.salePrice.length > 0
                      ? Number(ruleForm.salePrice).toFixed(2)
                      : "0.00"
                  }}
                </div>
              </div>
              <div class="desc">
                <span v-if="ruleForm.cardAlias">
                  {{
                    ruleForm.cardAlias.length > 0 ? ruleForm.cardAlias : "这里是卡面描述"
                  }}
                </span>
              </div>
            </div>
            <div class="bottom">
              <template v-if="ruleForm.timeLimitFlag.length > 0">
                <div>
                  <div v-if="ruleForm.timeLimitFlag == '1'">永久有效</div>
                  <div v-if="ruleForm.timeLimitFlag == '2'">
                    有效期：
                    <span v-if="ruleForm && ruleForm.fixStartTime.length > 0">
                      {{ dayjs(ruleForm.fixStartTime).format("YYYY年MM月DD日") }}</span
                    >
                    -
                    <span v-if="ruleForm && ruleForm.fixEndTime.length > 0">
                      {{ dayjs(ruleForm.fixEndTime).format("YYYY年MM月DD日") }}</span
                    >
                  </div>
                  <div v-if="ruleForm.timeLimitFlag == '3'">
                    购买后{{ ruleForm.timeLimitDays3 }}天内有效
                  </div>
                  <div v-if="ruleForm.timeLimitFlag == '4'">
                    使用后{{ ruleForm.timeLimitDays4 }}天有效
                  </div>
                </div>
              </template>
              <template v-else> 这里是有效期 </template>
            </div>
          </div>
          <div
            v-if="activeName == 'b'"
            class="card-content"
            :style="{
              backgroundImage: 'url(' + imgSrc + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }"
          >
            <div class="top">
              <el-icon>
                <eleme-filled />
              </el-icon>
              <span class="name">一指阁健康管理</span>
            </div>
            <div class="center">
              <div class="name-info">
                <div class="card-name">
                  {{
                    ruleForm.cardName.length > 0 ? ruleForm.cardName : "这里是卡面名称"
                  }}
                </div>
                <div class="price">
                  ¥{{
                    ruleForm.salePrice.length > 0
                      ? Number(ruleForm.salePrice).toFixed(2)
                      : "0.00"
                  }}
                </div>
              </div>

              <div class="desc">
                <span v-if="ruleForm.cardAlias">
                  {{
                    ruleForm.cardAlias.length > 0 ? ruleForm.cardAlias : "这里是卡面描述"
                  }}
                </span>
              </div>
            </div>
            <div class="bottom">
              <template v-if="ruleForm.timeLimitFlag.length > 0">
                <div>
                  <div v-if="ruleForm.timeLimitFlag == '1'">永久有效</div>
                  <div v-if="ruleForm.timeLimitFlag == '2'">
                    有效期：
                    <span v-if="String(ruleForm.fixStartTime).length > 0">
                      {{ dayjs(ruleForm.fixStartTime).format("YYYY年MM月DD日") }}</span
                    >
                    -
                    <span v-if="String(ruleForm.fixEndTime).length > 0">
                      {{ dayjs(ruleForm.fixEndTime).format("YYYY年MM月DD日") }}</span
                    >
                  </div>
                  <div v-if="ruleForm.timeLimitFlag == '3'">
                    购买后{{ ruleForm.timeLimitDays3 }}天内有效
                  </div>
                  <div v-if="ruleForm.timeLimitFlag == '4'">
                    使用后{{ ruleForm.timeLimitDays4 }}天有效
                  </div>
                </div>
              </template>
              <template v-else> 这里是有效期 </template>
            </div>
          </div>
        </div>
        <el-button
          @click="handleSaveCardClick(ruleFormRef)"
          class="nextBtn"
          size="large"
          type="primary"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </div>
  <add-serve-dialog
    :dialogVisible="addServeDialogVisible"
    @changeDialogVisible="changeAddServeDialogVisible"
  >
  </add-serve-dialog>
  <add-handsel-dialog
    :dialogVisible="addHandselDialogVisible"
    @changeDialogVisible="changeAddHandselDialogVisible"
  >
  </add-handsel-dialog>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted } from 'vue'
import serveForm from './cpns/serve-form/serve-form.vue'
import { buyLimit } from './config'
import addServeDialog from './cpns/add-serve-dialog/add-serve-dialog'
import addHandselDialog from './cpns/add-handsel-dialog/add-handsel-dialog'
import emitter from '@/utils/eventbus'
import store from '@/store'
import { COMPANY_ID } from '@/common/constant'
import { useRoute } from 'vue-router'
import uploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { updateFormRules } from '../../../../config'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
export default defineComponent({
  props: {

  },
  components: {
    serveForm,
    addServeDialog,
    addHandselDialog,
    uploadImg
  },

  setup() {
    const route = useRoute()
    const currentCardId = computed(() => route.query.cardId)

    const ruleForm = reactive({
      kindId: "1",
      cardName: "",
      salePrice: "",
      timeLimitFlag: "",
      buyerRoleFlag: "",
      buyRoleQty: '',
      cardBgImg: "",
      cardBgcolor: "rgba(200, 126, 52, 0.8)",
      expireUseFlag: "",
      companyId: COMPANY_ID,
      timeLimitDays2: "",
      timeLimitDays3: "",
      timeLimitDays4: "",
      timeLimitDays: '',
      fixStartTime: "",
      fixEndTime: "",
      exactPrice: 0,
      promPrice: 0,
      cardAlias: '',
      date: [],
      discount: 0,
      handselList2: null,
      displayApplet:null
    })

    const handleUploadRemove = () => {
      ruleForm.cardBgImg = ''
    }

    const ruleFormRef = ref()
    const activeName = ref('a')
    const handleTabsClick = (value) => {
    }

    const handleTabsChange = (v) => {
    }

    const cardDetail = computed(() => store.state.card.cardDetail)
    watch(cardDetail, (n) => {
      // console.log(1111, n)
      if (n != null) {
        ruleForm.kindId = n.kindId
        ruleForm.cardName = n.cardName
        ruleForm.salePrice = (n.salePrice) / 100
        ruleForm.timeLimitFlag = String(n.timeLimitFlag)
        ruleForm.buyerRoleFlag = String(n.buyerRoleFlag)
        ruleForm.buyRoleQty = n.buyRoleQty
        ruleForm.cardBgImg = n.cardBgImg
        ruleForm.cardBgcolor = n.cardBgcolor
        ruleForm.expireUseFlag = String(n.expireUseFlag)
        ruleForm.timeLimitDays = n.timeLimitDays
        ruleForm.displayApplet = n.displayApplet
        if (n.timeLimitFlag == 2) {
          ruleForm.fixStartTime = n.fixStartTime
          ruleForm.fixEndTime = n.fixEndTime
          ruleForm.date[0] = n.fixStartTime
          ruleForm.date[1] = n.fixEndTime
        }

        if(n.timeLimitFlag == 3){
          ruleForm.timeLimitDays3 = n.timeLimitDays
        }
        if(n.timeLimitFlag == 4){
          ruleForm.timeLimitDays4 = n.timeLimitDays
        }
        ruleForm.exactPrice = (n.exactPrice) / 100
        ruleForm.promPrice = (n.promPrice) / 100,
          ruleForm.cardAlias = n.cardAlias
        ruleForm.discount = n.discount
        emitter.emit('changeUpdateCardCardInfo', n.cardInfo)
        emitter.emit('changeUpdateCardShareWords', n.shareWords)
      }
    })

    const curretnOptServeList = ref([])
    emitter.on('changeUpdateCurretnOptServeList', (n) => {
      curretnOptServeList.value = n
      let optServeFlag = n.some(item => item.paraIdList.length > 0)
      if (!optServeFlag) {
        ruleForm.handselList2 = null
      } else {
        ruleForm.handselList2 = n
      }
      handleSalePriceChange()
    })

    const handleSalePriceChange = () => {
      let list = curretnOptServeList.value
      let sum = 0
      list.forEach(item => {
        let paraIdList = item.paraIdList
        paraIdList.forEach(ele => {
          sum += ele['salePrice'] * ele['Qty']
        })
      })

      list.forEach(item => {
        let paraIdList = item.paraIdList
        paraIdList.forEach(ele => {
          ele['averagePrice'] = ele['salePrice'] / sum * Number(ruleForm.salePrice)
        })
      })
    }

    const currentOptServePrice = ref(0)
    const getCardServeTotalPrice = () => {
      let sum = 0
      let serveList = curretnOptServeList.value
      serveList.forEach(item => {
        let project = item.paraIdList
        project.forEach(ele => {
          sum += ele.salePrice * ele.Qty
        })
      })
      currentOptServePrice.value = sum
    }

    watch(curretnOptServeList, (n) => {
      getCardServeTotalPrice()
    }, {
      deep: true,
      immediate: true
    })

    watch(() => ruleForm.buyerRoleFlag, (n) => {
      if (n == 0) {
        ruleForm.buyRoleQty = 0
      } 
      // else {
      //   ruleForm.buyRoleQty = ''
      // }
    }, {
      deep: true
    })

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key != 'cardBgcolor' && key != 'companyId' && key != 'exactPrice' && key != 'promPrice') {
          ruleForm[key] = ''
        }
      }
    }

    const addServeDialogVisible = ref(false)
    const changeAddServeDialogVisible = (flag) => {
      addServeDialogVisible.value = flag
    }

    const handleSiteHandselServeClick = () => {
      addServeDialogVisible.value = true
    }

    const addHandselDialogVisible = ref(false)
    const changeAddHandselDialogVisible = (flag) => {
      addHandselDialogVisible.value = flag
    }



    const handleSiteHandselGoodsClick = () => {
      addHandselDialogVisible.value = true
    }

    const handleColorPickerChange = (color) => {
      ruleForm.cardBgcolor = color
    }

    const currentHandselOptList = ref([])
    emitter.on('changeUpdateCurretnOptHandselList', n => {
      currentHandselOptList.value = n
    })

    const handleSaveCardClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          if (curretnOptServeList.value.length == 0 && ruleForm.kindId < 5) {
            ElMessage({
              message: '服务绑定至少绑定一个',
              type: 'warning',
            })
            return
          }
		  ruleForm.promPrice = ruleForm.promPrice * 100

          // 如果是储值卡 将本金金额赋值给储值金额
		  ruleForm.salePrice = ruleForm.salePrice * 100
          if (ruleForm.kindId == 5) {
            ruleForm.exactPrice = ruleForm.salePrice
          }

          let obj = {
            ruleForm: { ...ruleForm, cardId: currentCardId.value},
            handselList: currentHandselOptList.value,
            serveList: curretnOptServeList.value,
            cardId: currentCardId.value
          }

		  if (obj.ruleForm.handselList2) {
			for (let i = 0; i < obj.ruleForm.handselList2.length; i++) {
				for (let j = 0; j < obj.ruleForm.handselList2[i].paraIdList.length; j++) {
					obj.ruleForm.handselList2[i].paraIdList[j].averagePrice = obj.ruleForm.handselList2[i].paraIdList[j].averagePrice * 100
				}
			}
		  }

          store.dispatch('card/updateCardInfoAction', obj)
          emitter.emit('changeUpdateCardNextClick')
        } else {
          ElMessage({
            message: '带*为必填项，请正确填写数据!',
            type: 'warning',
          })
          // console.log('error submit!', fields)
        }
      })
    }

    const initPage = () => {
      store.dispatch('card/getCardDetailAction', {
        companyId: COMPANY_ID,
        cardId: currentCardId.value
      })
    }
    initPage()

    watch(() => route.path, (n) => {
      if (n == '/main/card/update') {
        curretnOptServeList.value = []
        currentHandselOptList.value = []
        initPage()
        emitter.emit('changeUpdateCardPreviousClick')
      }
    })

    const imgUploadRef = ref(null)
    const handleImgUploadSuccess = (res) => {
      ruleForm.cardBgImg = res.response.data.fileName
    }

    const handleDatetimerangeChange = value => {
      ruleForm.fixStartTime = dayjs(value[0]).format(
        'YYYY-MM-DD HH:mm:ss'
      )

      ruleForm.fixEndTime = dayjs(value[1]).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    }


    watch(() => ruleForm.cardBgImg, (n) => {
      if (n != null) {
        imgSrc.value =  n
      }
    })
    const imgSrc = ref('')

    const handleUploadImgEmpty = () => {
      ruleForm.cardBgImg = ''
    }

    watch(() => ruleForm.kindId, (n) => {
      emitter.emit('changeUpdateCardCardKind', n)
    }, {
      deep: true
    })

    emitter.on('updateCardServeFormServeRmove', (obj) => {
      removeServeClick(obj.serveId, obj.paraId)
    })

    const removeServeClick = (serveId, paraId) => {
      let index = curretnOptServeList.value.findIndex(item => item.serveId == serveId)
      let removeIndex = curretnOptServeList.value[index].paraIdList.findIndex(ele => ele.paraId == paraId)
      curretnOptServeList.value[index].paraIdList.splice(removeIndex, 1)
    }

    emitter.on('updateCardServeFormHandselServeRmove', (obj) => {
      removeHandselServeClick(obj.serveId, obj.paraId)
    })

    const removeHandselServeClick = (serveId, paraId) => {
      let index = currentHandselOptList.value.findIndex(item => item.serveId == serveId)
      let removeIndex = currentHandselOptList.value[index].paraIdList.findIndex(ele => ele.paraId == paraId)
      currentHandselOptList.value[index].paraIdList.splice(removeIndex, 1)

    }

    emitter.on('updateCardServeFormHandselGoodsRmove', (obj) => {
      removeGoodsClick(obj.goodId, obj.paraId)
    })

    const removeGoodsClick = (goodId, paraId) => {
      let index = currentHandselOptList.value.findIndex(item => item.goodId == goodId)
      let removeIndex = currentHandselOptList.value[index].paraIdList.findIndex(ele => ele.paraId == paraId)
      currentHandselOptList.value[index].paraIdList.splice(removeIndex, 1)
    }

    watch(addServeDialogVisible, (n) => {
      if (n == true) {
        emitter.emit('refreshUpdateCardPageAddserveDialog', curretnOptServeList.value)
      }
    }, {
      deep: true
    })

    watch(addHandselDialogVisible, (n) => {
      if (n == true) {
        emitter.emit('refreshUpdateCardPageAddhandselDialog', currentHandselOptList.value)
      }
    }, {
      deep: true
    })


    onUnmounted(() => {
      emitter.off('updateCardServeFormHandselGoodsRmove')
      emitter.off('updateCardServeFormHandselServeRmove')
      emitter.off('updateCardServeFormServeRmove')
      emitter.off('changeUpdateCurretnOptHandselList')
      emitter.off('changeUpdateCurretnOptServeList')
    })


    return {
      handleSalePriceChange,
      getCardServeTotalPrice,
      currentOptServePrice,
      handleDatetimerangeChange,
      ruleFormRef,
      handleUploadImgEmpty,
      imgSrc,
      handleTabsChange,
      handleTabsClick,
      activeName,
      imgUploadRef,
      handleImgUploadSuccess,
      currentHandselOptList,
      cardDetail,
      curretnOptServeList,
      handleSiteHandselGoodsClick,
      handleSiteHandselServeClick,
      changeAddHandselDialogVisible,
      addHandselDialogVisible,
      changeAddServeDialogVisible,
      addServeDialogVisible,
      ruleForm,
      handleColorPickerChange,
      buyLimit,
      handleSaveCardClick,
      UPLOAD_BASE_URL,
      updateFormRules,
      handleUploadRemove,
      dayjs
    }

  }
})
</script>

<style scoped lang="less">
.sum-price {
  .other-tips {
    font-size: 12px;
    color: rgba(128, 128, 128, 1);

    span {
      color: rgb(255, 106, 75);
    }
  }
}

&:deep(.el-upload-list__item-status-label) {
  display: none !important;
}

&:deep(.el-upload-list__item .el-icon--close) {
  display: block !important;
}

&:deep(.el-dialog) {
  min-width: 750px;
}

&:deep(.card-setting) {
  max-width: 300px;
}

.title {
  position: relative;
  padding-left: 12px;
  margin: 20px 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -3px;
    width: 6px;
    height: 22px;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(42, 130, 228, 1);
    font-size: 14px;
  }
}

.ruleForm {
  .tips {
    color: rgba(128, 128, 128, 1);
    font-size: 12px;
  }

  &:deep(.el-radio__label) {
    span {
      display: inline-block;
      width: 70px;
    }
  }

  &:deep(.el-upload--text) {
    color: rgb(72, 162, 255);
  }

  &:deep(.el-input) {
    max-width: 450px;
  }

  .nextBtn {
    margin-top: 30px;
    width: 156px;
    height: 48px;
  }

  .card-preview {
    padding: 4px 20px 20px 20px;
    max-width: 408px;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    border-radius: 6px;
    font-size: 14px;

    .card-content {
      padding: 20px;
      height: 200px;
      border-radius: 6px;
      font-size: 14px;
      color: white;
      position: relative;
      background-color: rgba(200, 126, 52, 0.8357142857142857);

      .top {
        display: flex;
        align-items: center;

        i {
          font-size: 26px;
        }
      }

      .center {
        font-size: 20px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 89%;

        .name-info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .price {
            font-size: 14px;
          }
        }

        .desc {
          font-size: 15px;
        }
      }

      .bottom {
        width: 88%;
        font-size: 14px;
        position: absolute;
        bottom: 20px;
        border-top: 1px solid rgba(145, 106, 67, 1);
      }
    }
  }

  .buy-limit {
    .el-select {
      margin-right: 20px;

      &:deep(.el-input) {
        max-width: 140px;
      }
    }

    &:deep(.buy-limit-input) {
      max-width: 300px;
    }
  }

  .discount {
    .tips {
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
    }

    .options {
      &:deep(.el-select) {
        max-width: 140px;
      }
    }
  }

  .expire-operation {
    .tips {
      color: rgba(166, 166, 166, 1);
      font-size: 12px;
      line-height: 20px;
    }
  }

  .aging {
    & > div {
      display: flex;
      margin-bottom: 20px;
    }

    &:deep(.el-input) {
      max-width: 140px;
      margin-right: 10px;
    }

    &:deep(.el-radio) {
      display: block !important;
      margin-right: 10px !important;

      .fixStartTime {
        margin-right: 10px;
      }

      .timeLimitDays {
        margin: 0 5px;
      }
    }
  }

  .handsel {
    .handsel-title,
    .handsel-list {
      display: flex;
      max-width: 806px;
      font-size: 12px;

      & > div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .handsel-list {
      & > div:nth-child(1) {
        border-left: 1px solid var(--borderColor);
      }

      & > div {
        height: 48px;
        border-right: 1px solid var(--borderColor);
        border-bottom: 1px solid var(--borderColor);

        &:deep(.el-input) {
          max-width: 140px;
        }
      }
    }

    .handsel-title {
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 12px;
    }
  }
}
</style>
